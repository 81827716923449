@import "../../../styles/base/abstracts";

.hbs-media-topper {
  @include grid-parent;
  @include site-max-width;
  @include topper-spacing;
  align-items: flex-end;
  padding-bottom: $grid-padding-mobile * 2;

  @include mq($bp-full-grid) {
    padding-bottom: $grid-padding * 2;
  }

  &.hbs-media-topper--3 {
    .hbs-media-topper__media-asset {
      @include mq($bp-desktop) {
        width: $col-4;
      }

      &:nth-child(2) {
        @include mq(max, $bp-desktop) {
          img {
            height: 100%;
          }
        }
        @include mq($bp-desktop) {
          width: $col-8;
        }
      }
      .hidden {
        display: none;
      }
    }
  }

  &.hbs-media-topper--no-subtitle {
    justify-content: space-between;
  }
}

.hbs-media-topper__title {
  @include h1;
  @include grid-child;
}

.hbs-media-topper__child {
  @include grid-child;
}

// Title
.hbs-media-topper__title {
  @include mq($bp-desktop) {
    align-self: flex-start;
    padding-bottom: $spacing-md;
    width: $col-10;
  }

  .hbs-media-topper:not(.hbs-media-topper--no-subtitle)
    &.hbs-media-topper__title--long {
    @include mq($bp-desktop) {
      h1 {
        @include h1;
      }
    }
  }

  .hbs-media-topper--no-subtitle &:not(.hbs-media-topper__title--long) {
    width: 100%;
    padding-bottom: $spacing-md;
    @include mq($bp-desktop) {
      align-self: flex-start;
      width: $col-12;

      h1 {
        @include giant;
      }
    }
  }

  .hbs-media-topper--no-subtitle &.hbs-media-topper__title--long {
    padding-bottom: $spacing-md;

    @include mq($bp-desktop) {
      align-self: flex-start;
      flex: 1;
      width: $col-16;
    }
  }
}

// Child
.hbs-media-topper__child {
  @include padding($spacing-md x);

  @include mq($bp-desktop) {
    padding-bottom: $spacing-lg;
    width: $col-6;
  }

  .hbs-media-topper--no-subtitle & {
    @include mq($bp-desktop) {
      display: flex;
      justify-content: flex-end;
      width: $col-4;
    }

    @include mq($bp-desktop-xl) {
      width: $col-3;
    }
  }
}

// Subtitle
.hbs-media-topper__subtitle {
  @include body-text($ff-sans);
}

.hbs-media-topper__cta {
  margin-top: $spacing-md;
}

// Media
.hbs-media-topper__media {
  display: flex;
  width: 100%;
}

.hbs-media-topper__media-asset {
  @include grid-child;

  .hbs-media-asset,
  .hbs-media-asset__wrapper,
  img,
  video {
    height: 100%;
    object-fit: cover;
  }

  figcaption {
    @include hide;
  }
}
